import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface EducationItem {
  id: string;
  idText: string;
  title: string;
  subtitle: string | undefined;
  type: "text" | "list";
  list: string[];
  time: string;
}

interface Props {
  expandedState: boolean;
  panel: string;
  onChange: () => void;
  data: EducationItem;
}

const EducationAccordionItem: React.FC<Props> = ({
  expandedState,
  panel,
  onChange,
  data,
}) => {
  return (
    <Accordion
      expanded={expandedState}
      onChange={onChange}
      sx={{
        backgroundColor: "rgba(0,128,128,0.5)",
        color: "white",
        margin: "4px 0",
        borderRadius: "8px !important",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore color="info" />}
        aria-controls={`${data.id}bh-content`}
        id={`${data.id}bh-header`}
      >
        <Typography
          sx={{
            width: "80%",
            flexShrink: 0,
            fontWeight: 600,
            wordWrap: "break-word",
          }}
        >
          {data.title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.subtitle && <Typography>{data.subtitle}</Typography>}
        {data.type === "text" && <Typography>{data.list[0]}</Typography>}
        {data.type === "list" && (
          <ul>
            {data.list.map((item, idx) => (
              <li key={idx}>{item}</li>
            ))}
          </ul>
        )}
        <Typography>{data.time}</Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default EducationAccordionItem;
