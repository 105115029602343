import { Box, Divider, SxProps } from "@mui/material";
import { SectionContainer, SectionContent, SectionTitle } from "./styles";

interface Props {
  id: string;
  children: any;
  sectionTitle: string;
  excludeDivider?: boolean;
  sx?: SxProps;
}

const PageSection: React.FC<Props> = ({
  id,
  children,
  sectionTitle,
  excludeDivider = false,
  sx,
}) => {
  return (
    <SectionContainer id={id} sx={{ ...sx }}>
      <SectionContent>
        <SectionTitle>{sectionTitle}</SectionTitle>
        <Box>{children}</Box>
        {!excludeDivider ? (
          <Divider
            sx={{ marginTop: "32px", borderColor: "rgba(255,255,255,0.2)" }}
          />
        ) : (
          <Box sx={{ paddingBottom: "20px" }} />
        )}
      </SectionContent>
    </SectionContainer>
  );
};

export default PageSection;
