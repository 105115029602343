import PageSection from "../../shared/components/PageSection";
import "./styles";
import { useState } from "react";
import { Box } from "@mui/material";
import ExperienceAccordionItem from "../../shared/components/AccordionItem/ExperienceAccordionItem";
import { experience } from "../../shared/constants/experience";

interface Props {
  excludeDivider?: boolean;
}

const Experience: React.FC<Props> = ({ excludeDivider }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (
    panel: string,
    isExpanded: boolean = expanded === panel
  ) => {
    setExpanded(isExpanded ? false : panel);
  };

  return (
    <PageSection
      id="experience"
      sectionTitle="work experience"
      excludeDivider={excludeDivider}
    >
      <Box mt="24px">
        {experience.map((item) => (
          <ExperienceAccordionItem
            expandedState={expanded === item.id}
            panel={item.id}
            onChange={() => handleChange(item.id)}
            data={item}
          />
        ))}
      </Box>
    </PageSection>
  );
};

export default Experience;
