import { Box, Typography, Divider } from "@mui/material";
import LinkItem from "../../shared/components/LinkItem/LinkItem";
import { HomeContainer, HomeContentContainer } from "./styles";
import { externalLinks } from "../../shared/constants/contacts";

const Home = () => {
  return (
    <HomeContainer id="home">
      <HomeContentContainer>
        <Box>
          <Typography
            fontWeight={600}
            color="white"
            sx={{ fontSize: "min(calc(1vw + 32px), 180px)" }}
          >
            VILIJA MAŽEIKĖ
          </Typography>
        </Box>

        <Divider
          sx={{
            margin: 2,
            opacity: 1,
            borderColor: "rgba(255,255,255,0.25)",
            borderWidth: "0.25px",
            width: "100%",
          }}
        />
        <Box>
          <Typography fontSize={24} color="white" marginBottom={2}>
            <code>frontend developer</code>
          </Typography>
          <Box
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {externalLinks.map((contact, idx) => (
              <LinkItem
                key={idx}
                href={contact.href}
                content={contact.content}
                linkType={contact.content}
                external={contact.external}
              />
            ))}
          </Box>
        </Box>
      </HomeContentContainer>
    </HomeContainer>
  );
};

export default Home;
