export const externalLinks = [
  {
    href: "mailto:vilija.mazeike@gmail.com",
    content: "email",
    external: true,
  },
  {
    href: "https://www.linkedin.com/in/vilija-mazeike/",
    content: "linkedin",
    external: true,
  },
  {
    href: "https://github.com/VilijaSetkute",
    content: "github",
    external: true,
  },
];
