import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Menu } from "@mui/icons-material";

export const MenuContainer = styled(Box)<{ isMenuOpen: boolean }>(
  ({ theme, isMenuOpen }) => ({
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    display: isMenuOpen ? "flex" : "none",
    backgroundColor: "#064141",
    flexDirection: "column",
    alignItems: "start",
    justifyContent: "center",
    color: "white",
    zIndex: 99,
    [theme.breakpoints.up("sm")]: {
      flexDirection: "row",
      alignItems: "center",
      display: "flex",
    },
  })
);

export const MenuIcon = styled(Menu)(({ theme }) => ({
  display: "block",
  width: "2em",
  height: "1.5em",
  position: "fixed",
  padding: "12px",
  top: 0,
  right: 0,
  zIndex: 99,
  cursor: "pointer",
  color: "white",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));
