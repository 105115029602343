import "./styles";
import CV from "../../assets/Vilija_Mazeike_CV.pdf";
import PageSection from "../../shared/components/PageSection/PageSection";
import { Typography, Button, Box, Collapse } from "@mui/material";
import { Download } from "@mui/icons-material";
import { useState } from "react";

interface Props {
  excludeDivider?: boolean;
}

const About: React.FC<Props> = ({ excludeDivider }) => {
  const [showFulltext, setShowFullText] = useState<boolean>(false);

  const showMore = () => {
    setShowFullText(true);
  };
  const showLess = () => {
    setShowFullText(false);
  };

  return (
    <PageSection
      id="about"
      sectionTitle="about"
      excludeDivider={excludeDivider}
      sx={{ backgroundColor: "rgba(0,47,47,1)" }}
    >
      <Typography marginY={3}>Greetings!</Typography>
      <Typography marginY={showFulltext ? 0 : 3}>
        I'm Vilija, a dedicated front-end developer driven by a passion for
        clean code and a thirst for continuous learning. With a foundation in
        market research and data analysis, I bring to the table a unique blend
        of structural thinking and meticulous planning.
        {!showFulltext && (
          <Box
            component="span"
            sx={{ fontSize: 14, opacity: 0.5, cursor: "pointer" }}
            onClick={showMore}
          >
            {" "}
            read more...
          </Box>
        )}
      </Typography>

      <Box component={Collapse} in={showFulltext} timeout="auto" unmountOnExit>
        <Typography marginY={3}>
          My journey into the world of front-end development was a natural
          progression. Over the course of 15 years in the market research
          sector, I evolved from project management to data analysis, and along
          the way, I became increasingly fascinated with the inner workings of
          various tools and platforms. I found myself drawn to the technical
          aspects - understanding how things function, optimizing workflows, and
          implementing code for automation.
        </Typography>
        <Typography marginY={3}>
          Before diving headfirst into front-end development, I dabbled in
          custom styling with CSS and delved into creating custom functionality
          with JavaScript for platforms like SurveyGizmo and ConfirmIt.
        </Typography>
        <Typography marginY={3}>
          My aspiration is simple yet profound: to be the kind of teammate you
          can always rely on, whether it's for coding solutions or providing
          thoughtful consultation on the best approach. I aim to be the voice of
          reason, infusing projects with a blend of sensibility and logic.
        </Typography>
        <Typography marginY={3}>
          Let's embark on this journey together, harnessing the power of
          technology to create seamless, user-centric experiences.{" "}
          {showFulltext && (
            <Box
              component="div"
              sx={{ fontSize: 14, opacity: 0.5, cursor: "pointer" }}
              onClick={showLess}
            >
              {" "}
              read less...
            </Box>
          )}
        </Typography>
      </Box>

      <Button
        variant="outlined"
        color="info"
        href={CV}
        target="_blank"
        type="application/octet-stream"
        rel="noreferrer"
        endIcon={<Download />}
      >
        Download resume (pdf)
      </Button>
    </PageSection>
  );
};

export default About;
