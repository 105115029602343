import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

interface PositionItem {
  id: string;
  idText: string;
  company: string;
  position: string;
  dates: string;
  extraInfo: string;
  responsibilities: string;
  technical: string[];
  isDeveloperPosition: boolean;
}

interface Props {
  expandedState: boolean;
  panel: string;
  onChange: () => void;
  data: PositionItem;
}

const ExperienceAccordionItem: React.FC<Props> = ({
  expandedState,
  panel,
  onChange,
  data,
}) => {
  return (
    <Accordion
      expanded={expandedState}
      onChange={onChange}
      sx={{
        backgroundColor: "rgba(0,128,128,0.5)",
        color: "white",
        margin: "4px 0",
        borderRadius: "8px !important",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore color="info" />}
        aria-controls={`${data.id}bh-content`}
        id={`${data.id}bh-header`}
      >
        <Typography sx={{ width: "33%", flexShrink: 0, fontWeight: 600 }}>
          {data.company}
        </Typography>
        <Typography sx={{ color: "rgba(255,255,255,0.75)" }}>
          {data.position} | {data.dates}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {data.responsibilities.length > 0 && (
          <Typography marginBottom={!!data.technical.length ? 1 : 0}>
            Responsibilities: {data.responsibilities}
          </Typography>
        )}
        <Typography>
          {data.technical.length === 1 && (
            <>
              <span>Technical skills:</span> {data.technical[0]}
            </>
          )}
          {data.technical.length > 1 && (
            <Box>
              {data.isDeveloperPosition ? (
                <Typography>Technical Competencies:</Typography>
              ) : (
                <Typography>Activities:</Typography>
              )}
              <ul>
                {data.technical.map((tech) => (
                  <li>{tech}</li>
                ))}
              </ul>
            </Box>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExperienceAccordionItem;
