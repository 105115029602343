import React from "react";
import { Email, LinkedIn, GitHub } from "@mui/icons-material";

interface Props {
  iconName: string;
}

const LinkIcon: React.FC<Props> = ({ iconName }) => {
  const iconMap: { [key: string]: React.ElementType } = {
    email: Email,
    linkedin: LinkedIn,
    github: GitHub,
  };

  const Icon = iconMap[iconName];
  const getScale = (iconName: string) => {
    switch (iconName) {
      case "email":
        return "calc( 30px * 1.4)";
      case "linkedin":
        return "calc( 30px * 1.2)";
      default:
        return "calc( 30px * 1.1176)";
    }
  };

  return (
    <Icon
      sx={{
        color: "white",
        height: getScale(iconName),
        width: getScale(iconName),
        marginX: 1,
        "&:hover": {
          color: "#119898",
        },
      }}
    />
  );
};
export default LinkIcon;
