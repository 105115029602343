import { styled } from "@mui/material/styles";
import { ExpandCircleDown } from "@mui/icons-material";

export const ScrollToTopIcon = styled(ExpandCircleDown)(({ theme }) => ({
  display: "flex",
  position: "fixed",
  bottom: 0,
  right: 0,
  alignItems: "center",
  height: "50px",
  justifyContent: "flex-end",
  zIndex: 1000,
  cursor: "pointer",
  animation: "fadeIn 0.3s",
  transition: "opacity 0.4s",
  opacity: 0.5,
  fontSize: "50px",
  margin: 5,
  color: "white",
  rotate: "180deg",
  [theme.breakpoints.up("sm")]: {
    display: "none",
  },
}));
