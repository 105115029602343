import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const SectionContainer = styled(Box)({
  backgroundColor: "rgba(6,65,65,1)",
});

export const SectionContent = styled(Box)({
  maxWidth: "900px",
  padding: "24px",
  margin: "0 auto",
  color: "white",
});

export const SectionTitle = styled(Typography)({
  textTransform: "uppercase",
  fontWeight: 600,
  fontSize: "24px",
});
