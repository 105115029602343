import preval from "preval.macro";
import { FooterText, FooterContainer } from "./styles";
import { Box } from "@mui/material";
import LinkItem from "../../shared/components/LinkItem";
import { externalLinks } from "../../shared/constants/contacts";

const Footer = () => {
  const dateTimeStamp = preval`module.exports = new Date().toLocaleString('en-EN', {
    year: "numeric",
    month: "long"
    });`;

  return (
    <FooterContainer>
      <FooterText>Developed by Vilija Mažeikė</FooterText>
      <FooterText>Last update {dateTimeStamp}</FooterText>
      <Box
        sx={{
          textAlign: "center",
          marginTop: "30px",
          marginBottom: "10px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {externalLinks.map((contact, idx) => (
          <LinkItem
            key={idx}
            href={contact.href}
            content={contact.content}
            linkType={contact.content}
            external={contact.external}
          />
        ))}
      </Box>
    </FooterContainer>
  );
};

export default Footer;
