import helpUAImage from "../../assets/projectImages/pagalbaukrainai.png";

export const projectList = [
  {
    bgImage: helpUAImage,
    techStack: "MERN stack",
    hrefLink: "https://pagalbaukrainai.lt/",
    lastUpdate: "Last update: 2022 May",
  },
];
