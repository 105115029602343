import PageSection from "../../shared/components/PageSection";
import "./styles";
import { useState } from "react";
import { Box } from "@mui/material";
import EducationAccordionItem from "../../shared/components/AccordionItem/EducationAccordionItme";
import { educationList } from "../../shared/constants/education";

interface Props {
  excludeDivider?: boolean;
}

const Education: React.FC<Props> = ({ excludeDivider }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (
    panel: string,
    isExpanded: boolean = expanded === panel
  ) => {
    setExpanded(isExpanded ? false : panel);
  };

  return (
    <PageSection
      id="education"
      sectionTitle="Courses, education"
      excludeDivider={excludeDivider}
    >
      <Box mt="24px">
        {educationList.map((item) => (
          <EducationAccordionItem
            expandedState={expanded === item.id}
            panel={item.id}
            onChange={() => handleChange(item.id)}
            data={item}
          />
        ))}
      </Box>
    </PageSection>
  );
};

export default Education;
