export const experience = [
  {
    id: "exp0",
    idText: "collapseExp0",
    company: "Reiz Tech",
    position: "Junior Frontend developer",
    dates: "2022 Nov - present",
    extraInfo: "",
    responsibilities: "",
    technical: [
      "Collaborating closely with designers and developers to bring website designs to life.",
      "Crafting clean, efficient code using React, styled components, Material UI, and for Razor pages, relying on plain CSS, HTML, and JavaScript for seamless functionality.",
      "Conducting thorough testing for cross-browser compatibility and smooth performance.",
      "Enhancing website speed and efficiency through meticulous code optimization.",
      "Seamlessly integrating external APIs and libraries for extended functionality.",
      "Utilizing Git for version control, ensuring seamless team collaboration.",
      "Actively participating in code reviews, offering constructive feedback for collective improvement.",
      "Swiftly identifying and resolving technical challenges to maintain progress.",
      "Actively contributing to the refinement of user stories, probing into logical flow for an enhanced user experience.",
      "Taking the lead in code refactoring efforts, and consistently communicating areas for improvement to advocate for enhanced code quality.",
    ],
    isDeveloperPosition: true,
  },
  {
    id: "exp1",
    idText: "collapseExp1",
    company: "Confistech",
    position: "Junior Frontend developer",
    dates: "2022 Aug - 2022 Nov",
    extraInfo: "",
    responsibilities: "",
    technical: [
      "Collaborating closely with client, developers to bring designs to life.",
      "Writing clean, efficient code using React.",
      "Integrating external APIs and libraries to enhance functionality.",
      "Using Git for version control, ensuring smooth collaboration within the team.",
      "Participating in code reviews and offering constructive feedback for collective improvement.",
      "Swiftly identifying and resolving technical challenges to maintain progress.",
      "Streamlining and optimizing React / React Native codebase for better performance and maintainability.",
      "Ensuring seamless app deployment to App Store Connect, meeting all necessary requirements.",
      "Facilitating in-app upgrades to the latest packages for an improved user experience.",
      "Implementing secure authentication using Apple ID for seamless user access.",
      "Utilizing the Parse Platform to enhance app functionality at an entry-level capacity.",
    ],
    isDeveloperPosition: true,
  },
  {
    id: "exp2",
    idText: "collapseExp2",
    company: "Self-learning",
    position: "Student",
    dates: "2021 May - always present",
    extraInfo: "",
    responsibilities: "",
    technical: [
      "Mentee at Women Go Tech (Selected as one of 20 finalists in the Frontend track)",
      "Self-learner at Women Go Tech 'Atrask Technologijas' (Gained insights into various IT positions, leading me to choose the Frontend track)",
      "Student at CodeAcademy (Completed courses listed in the Education section)",
      "Participated in CodeX 2022 hackathon (My first experience in a hackathon setting)",
      "Self-learner on Udemy, FrontendMaster, and Coursera platforms",
      "Initiated my first project (project listed in the Skills section) in response to an influx of refugees from the Ukraine. While it's no longer maintained, it provided invaluable experience in deploying a website.",
    ],
    isDeveloperPosition: false,
  },
  {
    id: "exp3",
    idText: "collapseExp3",
    company: "Nord Security",
    position: "Survey analyst",
    dates: "2020 May - 2021 April",
    extraInfo: "",
    responsibilities:
      "Online questionnaire script preparation (Alchemer); survey data processing, analysis; implementation of new solutions.",
    technical: [
      "Daily use of SPSS; some CSS and JS modification in Alchemer platform.",
    ],
    isDeveloperPosition: false,
  },
  {
    id: "exp4",
    idText: "collapseExp4",
    company: "Kantar",
    position: "Data specialist",
    dates: "2018 Oct - 2020 May",
    extraInfo: "",
    responsibilities:
      "Media survey data processing and preparation for data import to media data platforms; new methodologies analysis, and implementation; implementation and use of new tools.",
    technical: [
      "Daily use of SPSS; Python for data preparation, new methodology implementation.",
    ],
    isDeveloperPosition: false,
  },
  {
    id: "exp5",
    idText: "collapseExp5",
    company: "Inspired UM",
    position: "Research project manager",
    dates: "2016 Sep - 2018 Aug",
    extraInfo: "",
    responsibilities:
      "Online questionnaire script preparation (Alchemer); survey data processing, analysis; implementation of new solutions.",
    technical: [
      "Daily use of SPSS; minimal CSS and JS modification in Alchemer platform.",
    ],
    isDeveloperPosition: false,
  },
  {
    id: "exp6",
    idText: "collapseExp6",
    company: "Norstat",
    position: "Senior project manager",
    dates: "2008 Oct - 2016 Sep",
    extraInfo: "",
    responsibilities:
      "Communication with clients and pricing optimization; coordination of international projects, project management, and quality assurance; data preparation for end-users; online panels management.",
    technical: [
      "Daily use of SPSS; minimal JS modification in ConfirmIt platform.",
    ],
    isDeveloperPosition: false,
  },
  {
    id: "exp7",
    idText: "collapseExp7",
    company: "VšĮ VVKC",
    position: "Project manager",
    dates: "2006 Oct - 2016 Sep",
    extraInfo: "",
    responsibilities: "Project management; data analysis.",
    technical: ["Daily use of SPSS."],
    isDeveloperPosition: false,
  },
  {
    id: "exp8",
    idText: "collapseExp8",
    company: "Sermo (WorldOne Research)",
    position: "Project manager",
    dates: "2005 Dec - 2006 Oct",
    extraInfo: "",
    responsibilities: "Project management; daily progress reports.",
    technical: ["Daily use of SPSS."],
    isDeveloperPosition: false,
  },
  {
    id: "exp9",
    idText: "collapseExp9",
    company: "Artogama",
    position: "Sales manager",
    dates: "2005 Jun - 2006 Nov",
    extraInfo: "",
    responsibilities: "Project management; search of new clients.",
    technical: [],
    isDeveloperPosition: false,
  },
];
