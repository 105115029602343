import { useState } from "react";
import { ProjectCard, ProjectContent } from "./styles";
import { Box } from "@mui/material";
import LinkItem from "../../shared/components/LinkItem";

interface PortfolioItemProps {
  bgImage: string;
  techStack: string;
  hrefLink: string;
  lastUpdate: string;
}

const PortfolioItem: React.FC<PortfolioItemProps> = ({
  bgImage,
  techStack,
  hrefLink,
  lastUpdate,
}) => {
  const [show, setShow] = useState(false);

  return (
    <ProjectCard
      projectImage={bgImage}
      onMouseOver={() => setShow(true)}
      onMouseOut={() => setShow(false)}
    >
      {show && (
        <Box
          sx={{
            backgroundColor: "rgba(0,0,0,0.8)",
            width: "300px",
            height: "225px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            transition: "1s",
            borderRadius: "10px",
          }}
        >
          <ProjectContent>
            <Box>{techStack}</Box>
            <Box>
              <LinkItem
                href={hrefLink}
                content="chip"
                linkType="chip"
                external={true}
              />
            </Box>
            <Box marginTop={2}>{lastUpdate}</Box>
          </ProjectContent>
        </Box>
      )}
    </ProjectCard>
  );
};

export default PortfolioItem;
