import "./styles";
import PageSection from "../../shared/components/PageSection";
import { TagCloud } from "react-tagcloud";
import Portfolio from "../portfolio/Portfolio";
import {
  Box,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { SubSectionTitle } from "./styles";
import {
  skillList,
  softSkillList,
  articles,
  participation,
} from "../../shared/constants/skills";
import LinkItem from "../../shared/components/LinkItem";
import { Article, EventAvailable } from "@mui/icons-material";
import networkImage from "../../assets/networkImage.png";

const options = {
  luminosity: "light",
  hue: "blue",
};

interface Props {
  excludeDivider?: boolean;
}

const Skills: React.FC<Props> = ({ excludeDivider }) => {
  return (
    <PageSection
      sectionTitle="skills"
      id="skills"
      excludeDivider={excludeDivider}
      sx={{
        backgroundImage: `url("${networkImage}")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundBlendMode: "soft-light",
      }}
    >
      <SubSectionTitle>Hands on:</SubSectionTitle>
      <Box
        sx={{
          width: { xs: "80%", sm: "65%" },
          margin: "0 auto",
          textAlign: "center",
        }}
      >
        <TagCloud
          minSize={12}
          maxSize={35}
          tags={skillList}
          colorOptions={options}
        />
      </Box>
      <SubSectionTitle>Projects:</SubSectionTitle>
      <Portfolio />
      <SubSectionTitle>Writings:</SubSectionTitle>
      <List sx={{ padding: 0 }}>
        {articles.map((article) => (
          <ListItemButton
            disableRipple
            sx={{
              padding: 0,
              marginLeft: 2,

              alignItems: "flex-start",
              "& .MuiButtonBase-root:hover": {
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: "40px", marginTop: "4px" }}>
              <Article sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              sx={{ "& .MuiTypography-root": { padding: "0px 0" } }}
            >
              <LinkItem
                href={article.href}
                external
                content={article.content}
                linkType="article"
                description={article.description ?? undefined}
              />
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
      <SubSectionTitle>Participation:</SubSectionTitle>
      <List sx={{ padding: 0 }}>
        {participation.map((participant) => (
          <ListItemButton
            disableRipple
            sx={{
              padding: 0,
              marginLeft: 2,

              alignItems: "flex-start",
              "& .MuiButtonBase-root:hover": {
                backgroundColor: "rgba(0, 0, 0, 0)",
              },
            }}
          >
            <ListItemIcon sx={{ minWidth: "40px", marginTop: "4px" }}>
              <EventAvailable sx={{ color: "white" }} />
            </ListItemIcon>
            <ListItemText
              sx={{ "& .MuiTypography-root": { padding: "0px 0" } }}
            >
              <LinkItem
                href={participant.href}
                external
                content={participant.content}
                linkType="article"
                description={participant.description ?? undefined}
              />
            </ListItemText>
          </ListItemButton>
        ))}
      </List>
      <SubSectionTitle>Soft skills:</SubSectionTitle>
      <Box>
        <ul>
          {softSkillList.map((skill, idx) => (
            <li key={idx}>{skill}</li>
          ))}
        </ul>
      </Box>
    </PageSection>
  );
};

export default Skills;
