import "./App.css";
import { Box } from "@mui/material";
import Home from "./components/home/Home";
import MenuToolbar from "./components/menu/MenuToolbar";
import Footer from "./components/footer";
import About from "./components/about/About";
import Experience from "./components/experience/Experience";
import Skills from "./components/skills";
import Education from "./components/education";
import ScrollToTop from "./shared/components/ScrollToTop/ScrollToTop";

function App() {
  return (
    <>
      <ScrollToTop />
      <Box>
        <MenuToolbar />
        <Box>
          <Home />
          <About excludeDivider={true} />
          <Experience excludeDivider={true} />
          <Skills excludeDivider={true} />
          <Education excludeDivider={true} />
          <Footer />
        </Box>
      </Box>
    </>
  );
}

export default App;
