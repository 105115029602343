export const skillList = [
  { value: "HTML5", count: 20 },
  { value: "CSS3", count: 20 },
  { value: "Bootstrap", count: 15 },
  { value: "Material UI", count: 30 },
  { value: "Javascript", count: 35 },
  { value: "Typescript", count: 35 },
  { value: "React", count: 35 },
  { value: "Npm", count: 20 },
  { value: "Node.js", count: 20 },
  { value: "Express", count: 20 },
  { value: "MongoDB", count: 20 },
  { value: "Digital Ocean", count: 12 },
  { value: "GitHub", count: 13 },
  { value: "Git", count: 15 },
  { value: "SQL", count: 12 },
];

export const softSkillList = [
  "Managing projects with proficiency for seamless coordination and delivery.",
  "Thinking with a user-centric approach for visual and UX excellence.",
  "Solving problems and thinking critically with strength and precision.",
  "Communicating effectively through storytelling, presentation, and reporting.",
  "Strategizing with expertise in research design for data-driven insights.",
];

export const articles = [
  {
    href: "https://reiztech.recruitee.com/blog-accelerate-in-the-it-world",
    content: "Article on career change. 2022 Dec",
    description:
      "For this article, the author interviewed fellow career changers. We share how we started our journey and what actions we took to become a front-end developer.",
  },
];

export const participation = [
  {
    href: "https://devpost.com/software/meeting-notes-s0g5vw#updates",
    content: "Participation in CodeX 2022 Hackathon. 2022 Jun",
    description:
      "About the challenge: The product prototype makes automated meeting notes based on the meeting transcript. User has full control over what they summarize and over the result they get.",
  },
  {
    href: "https://www.youtube.com/watch?v=jvDwki1dxWo",
    content:
      "Participation in an online event for career-changers (in Lithuanian)",
    description:
      "I had the privilege of sharing my personal success story as a speaker at an online event. The topic of my presentation was 'How I Changed My Career 180 Degrees.' It was a gratifying experience to recount my journey of making a significant shift in my professional trajectory. I had the opportunity to inspire and offer insights to others who may be contemplating a similar career transformation.",
  },
];
