import { useState } from "react";
import { MenuContainer, MenuIcon } from "./styles";
import { Box, ClickAwayListener } from "@mui/material";
import LinkItem from "../../shared/components/LinkItem/LinkItem";

const menuItems = [
  { href: "#", name: "home" },
  { href: "#about", name: "about" },
  { href: "#experience", name: "experience" },
  { href: "#skills", name: "skills" },
  { href: "#education", name: "education" },
];

const MenuToolbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  const handleToggle = () => {
    setToggleMenu(!toggleMenu);
  };

  const closeMenu = () => {
    setToggleMenu(false);
  };

  return (
    <ClickAwayListener
      onClickAway={() => setToggleMenu(false)}
      mouseEvent={"onClick"}
      touchEvent={"onTouchStart"}
    >
      <Box>
        <Box>
          <MenuContainer isMenuOpen={toggleMenu}>
            {menuItems.map((item, idx) => (
              <LinkItem
                key={idx}
                href={item.href}
                content={item.name}
                linkType="menu"
                external={false}
                isMenuOpen={toggleMenu}
                closeMenu={closeMenu}
              />
            ))}
          </MenuContainer>
        </Box>
        <MenuIcon onClick={handleToggle} />
      </Box>
    </ClickAwayListener>
  );
};

export default MenuToolbar;
