import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProjectCard = styled(Box)<{ projectImage: string }>(
  ({ projectImage }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "300px",
    height: "225px",
    backgroundImage: `url('${projectImage}')`,
    backgroundSize: "300px",
    transition: "1s",
    borderRadius: "10px",
    margin: "10px",
  })
);

export const ProjectContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});
