import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
// import networkImage from "../../assets/networkImage.png";
import networkImage from "../../assets/networkImage.png";

export const HomeContainer = styled(Box)(({ theme }) => ({
  backgroundImage: `url("${networkImage}")`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  [theme.breakpoints.up("sm")]: {
    height: "600px",
  },
}));

export const HomeContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minWidth: "2vw",
  [theme.breakpoints.up("sm")]: {
    minWidth: "360px",
  },
}));
