import { useEffect, useState } from "react";
import { ScrollToTopIcon } from "./styles";

const ScrollToTop = () => {
  const [showScroll, setShowScroll] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    const changeWidth = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", changeWidth);
    return () => {
      window.removeEventListener("resize", changeWidth);
    };
  }, []);
  return (
    <>
      {screenWidth < 600 && (
        <ScrollToTopIcon
          sx={{ display: showScroll ? "flex" : "none" }}
          onClick={scrollTop}
        />
      )}
    </>
  );
};

export default ScrollToTop;
