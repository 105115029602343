import { Typography, Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FooterContainer = styled(Box)({
  backgroundColor: "#131212",
  padding: "20px",
  textAlign: "center",
});

export const FooterText = styled(Typography)({
  color: "white",
  fontSize: "14px",
});
