import { Link, Typography } from "@mui/material";
import "./styles";
import React from "react";
import LinkIcon from "./LinkIcon";

type Icon = "email" | "linkedin" | "github";
type Content = string | Icon;

interface LinkProps {
  href: string;
  content: Content;
  external: boolean;
  linkType: string;
  isMenuOpen?: boolean;
  description?: string;
  closeMenu?: () => void;
}

const LinkItem: React.FC<LinkProps> = ({
  href,
  content,
  external,
  isMenuOpen,
  description,
  linkType,
  closeMenu,
}) => {
  const getContentType = (linkType: string | undefined, content: string) => {
    switch (linkType) {
      case "email":
      case "linkedin":
      case "github":
        return <LinkIcon iconName={linkType} />;
      case "chip":
        return (
          <Typography
            fontWeight={500}
            sx={{
              textTransform: "capitalize",
              "&:hover": { backgroundColor: "teal" },
              paddingY: 1,
              paddingX: 2,
              marginTop: 2,
              borderRadius: "50px",
              border: "1px solid white",
            }}
          >
            Website
          </Typography>
        );
      case "menu":
        return (
          <Typography
            fontWeight={500}
            onClick={closeMenu}
            sx={{
              textTransform: "uppercase",
              "&:hover": { transform: "scale(1.05)" },
              paddingY: { xs: 2, sm: 2 },
              paddingX: { xs: 3, sm: 2 },
            }}
          >
            {content}
          </Typography>
        );
      default:
        return (
          <Typography
            fontWeight={500}
            sx={{
              paddingY: { xs: 2, sm: 2 },
              paddingX: { xs: 3, sm: 2 },
            }}
          >
            {content}
          </Typography>
        );
    }
  };
  return (
    <Link
      href={href}
      underline="none"
      color="inherit"
      target={external ? "_blank" : "_self"}
      rel={external ? "noreferrer" : "alternate"}
    >
      {getContentType(linkType, content)}
      {description}
    </Link>
  );
};

export default LinkItem;
