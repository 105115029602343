import "./styles";
import PortfolioItem from "./PortfolioItem";
import { Box } from "@mui/material";
import { projectList } from "../../shared/constants/portfolio";

const Portfolio = () => {
  return (
    <Box display="flex" flexWrap="wrap" justifyContent="center">
      {projectList.map((project) => (
        <PortfolioItem
          key={project.bgImage}
          bgImage={project.bgImage}
          techStack={project.techStack}
          hrefLink={project.hrefLink}
          lastUpdate={project.lastUpdate}
        />
      ))}
    </Box>
  );
};

export default Portfolio;
