interface EducationItem {
  id: string;
  idText: string;
  title: string;
  subtitle: string | undefined;
  type: "text" | "list";
  list: string[];
  time: string;
}
export const educationList: EducationItem[] = [
  {
    id: "edu1",
    idText: "education1",
    title: "TypeScript extended course",
    subtitle: "SYLLABUS (total duration 1060 hours)",
    type: "list",
    list: [
      "Interactive content design (HTML, CSS)",
      "JavaScript, JavaScript algorithms",
      "User-side software development and improvement",
      "Versioning",
      "Analysis of software system requirements",
      "Data structures",
      "Software development with TypeScript and React.js",
      "Server-side software development",
      "Databases",
      "Working on project with whole class using GitHub, applying Agile principles",
    ],
    time: "2021 Sep - 2022 May | CodeAcademy",
  },
  {
    id: "edu2",
    idText: "education2",
    title: "Frontend Masters | Beginner track",
    subtitle: "SYLLABUS (total duration 28 hours)",
    type: "list",
    list: [
      "Complete Intro to Web Development, v2 BY Brian Holt (Stripe)",
      "Getting Started with CSS BY Jen Kramer (Freelance instructor)",
      "Getting Started with JavaScript, v2 BY Kyle Simpson (You Don't Know JS)",
      // eslint-disable-next-line no-script-url
      "JavaScript: From Fundamentals to Functional JS, v2 BY Bianca Gandolfo (Thumbtack)",
      "Website Accessibility, v2 BY Jon Kuperman (Cloudflare)",
    ],
    time: "2022 Jul | FRONTEND MASTERS",
  },
  {
    id: "edu3",
    idText: "education3",
    title: "Programming Foundations with JavaScript, HTML and CSS",
    subtitle: undefined,
    type: "text",
    list: ["Html, JavaScript, Cascading Style Sheets (CCS), Html5"],
    time: "2021 Sep | COURSERA",
  },
  {
    id: "edu4",
    idText: "education4",
    title: "Data analytics",
    subtitle: undefined,
    type: "text",
    list: ["Python (Pandas, Matplotlib), Jupyter Notebooks, SQL, PowerBI"],
    time: "2018 Apr | VILNIUS CODING SCHOOL",
  },
  {
    id: "edu5",
    idText: "education5",
    title: "Data Management and Visualization",
    subtitle: undefined,
    type: "text",
    list: ["Data Analysis, Python Programming, Data Management"],
    time: "2016 Oct | COURSERA",
  },
  {
    id: "edu6",
    idText: "education6",
    title: "Data analysis tools",
    subtitle: undefined,
    type: "text",
    list: [
      "Chi-Squared (Chi-2) Distribution, Data Analysis, Statistical Hypothesis Testing, Analysis Of Variance (ANOVA)",
    ],
    time: "2016 Sep / COURSERA",
  },
  {
    id: "edu7",
    idText: "education7",
    title: "Sociology | MA",
    subtitle: "Thesis:",
    type: "text",
    list: ["The Practice of Online Panel Surveys in Lithuania in Year 2009"],
    time: "2008 - 2010 / Vilnius University",
  },
];
